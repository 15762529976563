/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
// @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@200&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap');
// @import '~@fullcalendar/core/main.css';
// @import '~@fullcalendar/daygrid/main.css';
@font-face {
    font-family: 'Quicksand';
    src: url('./assets/fonts/Quicksand-VariableFont_wght.ttf');
    font-weight: 500;
    font-style: normal;
  }
@font-face {
    font-family: 'Public Sans';
    src: url('./assets/fonts/PublicSans-Italic-VariableFont_wght.ttf');
    font-weight: 200;
    font-style: normal;
  }
@font-face {
    font-family: 'Poppins';
      src: url('./assets/fonts/Poppins-Regular.ttf');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Raleway';
    src: url('./assets/fonts/Raleway-VariableFont_wght.ttf');
    font-weight: 400;
    font-style: normal;
}


h1,h2,h3,h4,h5,h6,p{
    margin: 0;
    padding: 0;
}

body{
    background: white;
}

.modal-note{
    padding-top: 119% !important;
    .modal-wrapper{
        border-top-left-radius: 30px !important;
        border-top-right-radius: 30px !important;
    }
}


@media only screen and (max-height: 800px) {
    .modal-note{
        padding-top: 120% !important;
    }
}

@media only screen and (max-height: 800px) {
    .modal-note{
        padding-top: 80% !important;
    }
}

@media only screen and (max-height: 740px) {
    .modal-note{
        padding-top: 70% !important;
    }
}

@media only screen and (max-height: 640px) {
    .modal-note{
        padding-top: 65% !important;
    }
}


.modal-note0{
    padding-top: 100% !important;
    .modal-wrapper{
        border-top-left-radius: 30px !important;
        border-top-right-radius: 30px !important;
    }
}


@media only screen and (max-height: 800px) {
    .modal-note0{
        padding-top: 101% !important;
    }
}

@media only screen and (max-height: 800px) {
    .modal-note0{
        padding-top: 60% !important;
    }
}

@media only screen and (max-height: 740px) {
    .modal-note0{
        padding-top: 50% !important;
    }
}

@media only screen and (max-height: 640px) {
    .modal-note0{
        padding-top: 45% !important;
    }
}

ion-progress-bar{
    --background: #E5E5E5;
}
.modal-contrat{
   width: 100% !important;
   height: 100% !important;
   .modal-wrapper{
        width: 100% !important;
        height: 100% !important;
   }
}

.modal-add-date{
    padding-top: 79% !important;
    .modal-wrapper{
        border-top-left-radius: 30px !important;
        border-top-right-radius: 30px !important;
    }
}


@media only screen and (max-height: 800px) {
    .modal-add-date{
        padding-top: 80% !important;
    }
}

@media only screen and (max-height: 800px) {
    .modal-add-date{
        padding-top: 40% !important;
    }
}

@media only screen and (max-height: 740px) {
    .modal-add-date{
        padding-top: 30% !important;
    }
}

@media only screen and (max-height: 640px) {
    .modal-add-date{
        padding-top: 25% !important;
    }
}




.modal-signature{
    padding-top: 20% !important;
    background: none !important;
    .modal-wrapper{
        border-top-left-radius: 30px !important;
        border-top-right-radius: 30px !important;
    }
}


@media only screen and (max-height: 800px) {
    .modal-signature{
        padding-top: 20% !important;
    }
}

@media only screen and (max-height: 800px) {
    .modal-signature{
        padding-top: 15% !important;
    }
}

@media only screen and (max-height: 740px) {
    .modal-signature{
        padding-top: 15% !important;
    }
}

@media only screen and (max-height: 640px) {
    .modal-signature{
        padding-top: 15% !important;
    }
}

.modal-editprofil{
    padding-top: 100% !important;
    .modal-wrapper{
        border-top-left-radius: 30px !important;
        border-top-right-radius: 30px !important;
    }
}


.modal-delete{
    padding-top: 137% !important;
    .modal-wrapper{
        border-top-left-radius: 30px !important;
        border-top-right-radius: 30px !important;
    }
}


@media only screen and (max-height: 800px) {
    .modal-note{
        padding-top: 138% !important;
    }
}

@media only screen and (max-height: 800px) {
    .modal-note{
        padding-top: 98% !important;
    }
}

@media only screen and (max-height: 740px) {
    .modal-note{
        padding-top: 88% !important;
    }
}

@media only screen and (max-height: 640px) {
    .modal-note{
        padding-top: 83% !important;
    }
}

.w-toast{
    --width: 100% !important;
    --height: 40px !important;
    --color: #FFFFFF !important;
    --border-radius: none !important;
    --background: #C84F94 !important;
    --border: none !important;
    --font-family: Poppins;
    --font-style: normal;
    --font-weight: 500;
    --font-size: 22px;
}


.w-selected-day{
    border: 1px solid #C84F94;
    border-radius: 50%;
}

.popover-agenda{
    .popover-content{ min-width: 320px !important; }
}


app-hide-modalx, app-hide-modal{
    z-index: 10000;
}

ion-popover{
    ion-backdrop{
        opacity: 0.5 !important;
    }
        .popover-wrapper{
            .popover-content{
                max-width:500px;
                min-width: 322px;
            }
        }
    }



.modal-prestation {
    padding-top: 5% !important;
    background: none !important;
    .modal-wrapper{
        border-top-left-radius: 30px !important;
        border-top-right-radius: 30px !important;
    }
}

.cordova-camera-select{
    background: #dbdbdb;
    padding: 20px;
    bottom: 0;
    position: absolute !important;
    width: 100%;
    height: 130px;
    padding-top: 50px;
}

.stripeCardContent {
    padding: 10px;
}

ion-checkbox{
    --border-color:	rgb(0, 0, 0) !important;
     border-color : rgb(0, 0, 0) !important;
 
   }


// CUSTOM RANGER SLIDE
// ::ng-deep {
.custom-slider .ngx-slider .ngx-slider-bar {
    background: rgba(201, 80, 149, 0.32);
    height: 6px;
  }
  
  .custom-slider .ngx-slider .ngx-slider-selection {
    background: #c95095;
  }
  
  .custom-slider .ngx-slider .ngx-slider-pointer {
    width: 20px;
    height: 20px;
    top: -7px;
    bottom: 0;
    background: #c95095;
  }
  
  .custom-slider .ngx-slider .ngx-slider-pointer:after {
    display: none;
  }
  
  .alert{
    padding: 10px;
    width: 100%;
    &.alert-info{
        color: #0c5460;
        background-color: #d1ecf1;
        border-color: #bee5eb;
    }
    &.alert-white{
        color: #0c5460;
        background-color: #ffffff;
        border-color: #ffffff;
    }
}

.alert-success{
  color:green;
  background-color: #d1f1dc;
  border-color: #beebd8;
}
.alert-danger{
  color:rgb(239, 59, 59);
  background-color: #f1d1d1;
  border-color: #ebccbe;
}

  .alert-wrapper.sc-ion-alert-ios{
    --background: white !important;
  }
  .custom-slider .ngx-slider .ngx-slider-bubble {
    bottom: 15px;
  }
  
  .custom-slider .ngx-slider .ngx-slider-limit {
    font-weight: bold;
    background: white;
  }
  
  .custom-slider .ngx-slider .ngx-slider-tick {
    width: 1px;
    height: 16px;
    margin-left: 5px;
    border-radius: 0;
    background: #c95095;
    top: -1px;
  }
  
  .custom-slider .ngx-slider .ngx-slider-tick.ngx-slider-selected {
    background: rgba(201, 80, 149, 0.32);
  }
  
  /** FIN CUSTOM SLIDER */